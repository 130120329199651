body {
    background-color: #002747;
}

.inicio {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Oswald', sans-serif;
}

.texto {
    width: 45%;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
}

.texto p {
    color: #ECAB0F;
    font-weight: 600;
    font-size: 25px;
}

.texto h1 {
    width: 100%;
    color: #fff;
    font-family: 'Concert One', cursive;
    font-size: 60px;
}

.texto h3 {
    font-size: 20px;
    color: #7A7A7A;
}

/* -------------------------------- */
.personaimage {
    width: 45%;
    height: auto;
    overflow-y: hidden;
}

.personaimage img {
    width: 100%;
    height: 85%;
}