footer{
    position: relative;
    width: 100vw;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.fundo{
    width: 100vw;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #3586FF;
}
.socialMedias{
    height: 70px;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.socialMedias img{
    width: 30px;
    height: 30px;
    cursor: pointer;
    transform: translateY(0px);
    transition: .5s all ease-in-out;
}
.socialMedias img:hover{
    transition: 1s all ease;
    transform: translateY(-10px);
}

.menu{
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.menu li{
    color: #fff;
    font-size: 18px;
}
.wave{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height:100px;
    background: url(../../Image/wave.png);
    background-size: 1000px 110px;
    opacity: 1;
    bottom: 0;
    z-index: 2;
    animation: animateWave 4s linear infinite;
}
.wave2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100px;
    background: url(../../Image/wave.png);
    background-size: 1000px 100px;
    opacity: 0.5;
    bottom: 5px;
    z-index: 2;
    animation: animateWave2 4s linear infinite;
}
.wave3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100px;
    background: url(../../Image/wave.png);
    background-size: 1100px 100px;
    opacity: 0.8;
    bottom: 15px;
    z-index: 3;
    animation: animateWave2 5s linear infinite;
}
.wave4{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100px;
    background: url(../../Image/wave.png);
    background-size: 1000px 100px;
    opacity: 0.7;
    bottom: 0px;
    z-index: 4;
    animation: animateWave2 5s linear infinite;
}

@keyframes animateWave{
    from {
        background-position-x: 1000px;
    }
    to {
        background-position-x:0px;
    }
}

@keyframes animateWave2{
    from {
        background-position-x: 0px;
    }
    to {
        background-position-x:1000px;
    }
}

