article {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.introducao {
    width: 85%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    text-align: left;
}

.icones {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #070149;
}

.icones img {
    width: 60%;
    height: 60%;
}

.introducao h2,
.destaque {
    color: #ECAB0F;
}

.introducao h5 {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #a6a6a6;
}
/* --------------------------------------- */
.missao {
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.marca {
    width: 90%;
    height: 100%;
}
.marca img {
    width: 100%;
    height: 550px;
    border-radius: 10px;
}
/* ---------------------------------- */
.valores{
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.valores .introducao{
    width: 100%;
}
.cards {
    width: 100%;
    height: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    background-image: url(../../Image/valores.png);
    background-repeat: no-repeat;
    background-size: cover;
}