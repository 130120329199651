section {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.introducao {
    width: 92%;
    height: 90px;
    display: flex;
    align-items: center;
    gap: 30px;
}
.icones {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #070149;
}

.icones img {
    width: 60%;
    height: 60%;
}
.introducao h2{
    color: #ECAB0F;
}

/* ---------------------------------------------- */
.cards {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.card {
    width: 20%;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    background-color: #070149;
    border-top: 5px solid #ECAB0F;
    border-radius:10px;
}
.icons {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icons img{
    width: 100%;
    height: 100%;
}
.cards h3 {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
}
.cards p {
    font-size: 15px;
    font-weight: bold;
    color: #7A7A7A;
}
.cards button {
    width: 100%;
    display: inline-block;
    border-radius: 7px;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 15px;
    cursor: pointer;
    background-color: #018aeb;
}
button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}
button span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}
button:hover span {
    padding-right: 20px;
    color: #ECAB0F;
}
button:hover span:after {
    opacity: 1;
    right: 0;
    transition: all .3s;
}
