@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  overflow-x: hidden;
  font-family: 'Oswald', sans-serif;
}

::-webkit-scrollbar{
  width: 12px;
  background:#018AEB;
  border: 5px solid #012949;
}

::-webkit-scrollbar-thumb{
  background: #018AEB;
  border-radius: 50px;  
}

::-webkit-scrollbar-thumb:hover{
  background: #018aeba6;
}
