header{
    width: 100vw;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Oswald', sans-serif;
}
.logo{
    height: 100px;
    width: 10%;
    overflow-y: hidden;
}
.logo img{
    width: 100%;
    height: 100%;
}
nav{
    position: relative;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
nav li{
    color: #fff;
}
nav li:hover{
    transition: all .2s ease-in-out;
    color: #ECAB0F;
}
.botao{
    width: 10%;
}
button {
    position: relative;
    width: 100%;
    display: block;
    cursor: pointer;
    background: transparent;
}

.button {
    color: #ECAB0F;
    padding: 0.3em 0.8em;
    background-color: transparent;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: bold;
    font-size: 17px;
    border: 1px solid;
    text-transform: uppercase;
    z-index: 1;
}

.button::before,
.button::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: #018AEB;
    transition: .9s ease-in-out;
}

.button::before {
    top: -1em;
    left: -1em;
}

.button::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.button:hover::before,
.button:hover::after {
    height: 410px;
    width: 410px;
}

.button:hover {
    color: #fff;
}

.button:active {
    filter: brightness(.8);
}