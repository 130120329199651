aside{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}
.icones {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #018aeb;
}
.introducao {
    height: 90px;
    display: flex;
    align-items: center;
    gap: 30px;
}
.icones {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #070149;
}

.icones img {
    width: 60%;
    height: 60%;
}
.introducao h2{
    color: #ECAB0F;
}

.parcerias{
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.acordeon{
    width: 35%;
    height: 600px;
    background-color: aqua;
}

